import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/steel",
    name: "Steel",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/steel/index.vue"),
    meta: {
      title: "数字钢铁平台能力体验",
    },
  },
  {
    path: "/steelTTS",
    name: "SteelTTS",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/steelTTS/index.vue"),
    meta: {
      title: "数字钢铁平台能力体验",
    },
  },
  {
    path: "/web/steelTTS",
    name: "WebSteelTTS",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/webSteelTTS/index.vue"),
    meta: {
      title: "数字钢铁平台能力体验",
    },
  },
  {
    path: "/culture",
    name: "Culture",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/culture/index.vue"),
    meta: {
      title: "西宁城北区文旅能力体验",
    },
  },
  {
    path: "/cultureTTS",
    name: "CultureTTS",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/cultureTTS/index.vue"),
    meta: {
      title: "西宁城北区文旅能力体验",
    },
  },
  {
    path: "/web/cultureTTS",
    name: "WebCultureTTS",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/webCultureTTS/index.vue"),
    meta: {
      title: "西宁城北区文旅能力体验",
    },
  },
  {
    path: "/medicine",
    name: "Medicine",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/medicine/index.vue"),
    meta: {
      title: "青海新绿洲药业能力体验",
    },
  },
  {
    path: "/medicineTTS",
    name: "MedicineTTS",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/medicineTTS/index.vue"),
    meta: {
      title: "青海新绿洲药业能力体验",
    },
  },
  {
    path: "/web/medicineTTS",
    name: "WebMedicineTTS",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/webMedicineTTS/index.vue"
      ),
    meta: {
      title: "青海新绿洲药业能力体验",
    },
  },
  {
    path: "/mobile",
    name: "MobileDialogDigitalPeople",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/mobile-dialog-digital-people/index.vue"
      ),
    meta: {
      title: "新绿洲药业集团医药数字人",
    },
  },
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/dialog-digital-people/index.vue"
      ),
    meta: {
      title: "新绿洲药业集团医药数字人",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
